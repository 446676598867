import React, { useState, useEffect, useMemo } from "react";
import api from "../../../utils/api";
import { ProfileTable } from "../../../components/Tables/ProfileTable";
import { Filters } from "../../../components/Tables/Filters/Filters";

export const ProfilePage = () => {
  const filtersOptions = useMemo(
    () => ({
      WAITING: "waiting",
      APPROVED: "approved",
      ALL: "all",
    }),
    []
  );

  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [approvedFilter, setApprovedFilter] = useState(filtersOptions.WAITING);
  const [filters, setFilters] = useState({});
  const fetchProfiles = async () => {
    try {
      const response = await api.get("/admin/profile");
      setProfiles(response.data.profiles);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };
  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    const normalizedFilter = filters?.name
      ? filters?.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : "";

    let filtered = profiles.filter((user) =>
      user.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(normalizedFilter)
    );

    if (filters?.instituteId) {
      filtered = filtered.filter(
        (user) => user.institute?.id === parseInt(filters.instituteId)
      );
    }

    if (filters?.busLineId) {
      filtered = filtered.filter(
        (user) => user.busLine?.id === parseInt(filters.busLineId)
      );
    }

    switch (approvedFilter) {
      case filtersOptions.WAITING:
        setFilteredProfiles(filtered.filter((user) => !user.isApproved));
        break;
      case filtersOptions.APPROVED:
        setFilteredProfiles(filtered.filter((user) => user.isApproved));
        break;
      case filtersOptions.ALL:
        setFilteredProfiles(filtered);
        break;
      default:
        setFilteredProfiles(filtered);
    }
  }, [profiles, approvedFilter, filtersOptions, filters]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-8">
        <h1 className="text-2xl font-semibold text-gray-800 text-center">
          Acadêmicos
        </h1>
        <div className="flex space-x-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setApprovedFilter(filtersOptions.ALL)}
          >
            Todos
          </button>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={() => setApprovedFilter(filtersOptions.APPROVED)}
          >
            Aprovados
          </button>
          <button
            className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
            onClick={() => setApprovedFilter(filtersOptions.WAITING)}
          >
            Aguardando Aprovação
          </button>
        </div>
        <Filters
          filters={filters}
          setFilters={setFilters}
          busLineId
          instituteId
        />
      </div>
      <ProfileTable profiles={filteredProfiles} updateList={fetchProfiles} />
    </div>
  );
};
