import { Link as RouterLink, Outlet } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Grid,
  Link,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Header } from "../../components/Header";

export function AdminLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={RouterLink} to="/manager/events">
          <ListItemText primary="Eventos" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/profiles">
          <ListItemText primary="Usuários" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/lines">
          <ListItemText primary="Linhas" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/faculdades">
          <ListItemText primary="Instituições" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/cursos">
          <ListItemText primary="Cursos" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/payments">
          <ListItemText primary="Pagamentos" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/reports">
          <ListItemText primary="Relatórios" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/declaracoes">
          <ListItemText primary="Declarações de matrícula" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manager/ouvidoria">
          <ListItemText primary="Ouvidoria" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Header />
      <AppBar position="static" sx={{ marginBottom: "20px" }}>
        <Toolbar className="bg-gray-900" sx={{ paddingLeft: 0 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            {/* Ícone de menu para visão mobile */}
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" }, marginLeft: '2rem' }}
            >
              <MenuIcon />
            </IconButton>
            {/* Navegação para telas maiores */}
            <Grid item sx={{ display: { xs: "none", sm: "flex" }, background: 'transparent', flexGrow: 1 }}>
              <Box
                component="nav"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center", background: 'transparent'
                }}
              >
                <NavItem to="/manager/events">Eventos</NavItem>
                <NavItem to="/manager/profiles">Usuários</NavItem>
                <NavItem to="/manager/lines">Linhas</NavItem>
                <NavItem to="/manager/faculdades">Instituições</NavItem>
                <NavItem to="/manager/cursos">Cursos</NavItem>
                <NavItem to="/manager/payments">Pagamentos</NavItem>
                <NavItem to="/manager/reports">Relatórios</NavItem>
                <NavItem to="/manager/declaracoes">Declarações de matrícula</NavItem>
                <NavItem to="/manager/ouvidoria">Ouvidoria</NavItem>
                <NavItem to="/manager/eleicoes">Eleições</NavItem>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* Drawer para visão mobile */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { sm: "none" }, backgroundColor: "#fff", }}
      >
        {drawer}
      </Drawer>
      <Box
        sx={{
          backgroundColor: "#fff",
          margin: "2rem",
          borderRadius: "1rem",
          padding: "1rem",
          overflowY: "auto",
          maxHeight: "calc(100vh - 160px)",
        }}
      >
        <Outlet />
      </Box>
    </React.Fragment>
  );
}

function NavItem({ to, children }) {
  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{ marginRight: "1rem", color: "white", textDecoration: "none", whiteSpace: "nowrap" }}
    >
      {children}
    </Link>
  );
}
