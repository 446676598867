import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PaginaVotacao } from "./PaginaVotacao";

export const Votacao = () => {
  return <PaginaVotacao />;
};
