import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import useAuth from "../../hooks/useAuth";
import api from "../../utils/api";
import { LoginButton } from "../LoginButton";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export function Header() {
  const { authenticated, isAdmin, userId } = useAuth();
  const [isApproved, setIsApproved] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (userId === 0) return;

    async function fetchData() {
      try {
        const url = `/admin/profile/${userId}`;
        const response = await api.get(url);
        setIsApproved(response.data?.profiles[0]?.isApproved);

        setNotifications([]);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, [userId]);

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const awaiting = true;

  const headerClasses = isAdmin ? "bg-gray-900" : "bg-green-800";
  return (
    <AppBar position="static" className={headerClasses} sx={{ width: "100%" }}>
      <Toolbar
        className={headerClasses}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap", // Ajusta o conteúdo para quebrar em múltiplas linhas se necessário
          bgcolor: isAdmin ? "grey.900" : "green.800",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            flexWrap: "wrap", // Permite que os botões se ajustem em telas menores
          }}
        >
          <NavLink
            to="/"
            title="Inicio"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button color="inherit">Início</Button>
          </NavLink>
          {authenticated ? (
            <>
              {isApproved && (
                <NavLink
                  to="/carteirinha"
                  title="Carteirinha"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Carteirinha</Button>
                </NavLink>
              )}
              {isApproved && (
                <NavLink
                  to="/financeiro"
                  title="Financeiro"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Financeiro</Button>
                </NavLink>
              )}
              {/* {isApproved && (
                <NavLink
                  to="/votacao"
                  title="Financeiro"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Votar</Button>
                </NavLink>
              )} */}
              {!awaiting && (
                <NavLink
                  to="/eventos"
                  title="Eventos"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Informações</Button>
                </NavLink>
              )}
              <NavLink
                to="/cadastro"
                title="Cadastro"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button color="inherit">Cadastro</Button>
              </NavLink>
              {/* <NavLink
                to="/votacao"
                title="Votar"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button color="inherit">Votar</Button>
              </NavLink> */}
              <NavLink
                to="/ouvidoria"
                title="Ouvidoria"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button color="inherit">Ouvidoria</Button>
              </NavLink>
              <NavLink
                to="/financeiro"
                title="Financeiro"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button color="inherit">Financeiro</Button>
              </NavLink>
              {!awaiting && (
                <NavLink
                  to="/documentos"
                  title="Documentos"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Documentos</Button>
                </NavLink>
              )}
            </>
          ) : (
            <>
              {authenticated && (
                <NavLink
                  to="/register"
                  title="Cadastro"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button color="inherit">Cadastro</Button>
                </NavLink>
              )}
            </>
          )}
          {isAdmin && (
            <NavLink
              to="/manager/events"
              title="Informações"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Informações</Button>
            </NavLink>
          )}
          {!authenticated && (
            <NavLink
              to="/login"
              title="Acesso à plataforma"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Acesso à plataforma</Button>
            </NavLink>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {authenticated ? (
            <IconButton color="inherit" onClick={handleNotificationClick}>
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          ) : (
            <div></div>
          )}
          <LoginButton />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleNotificationClose}
          >
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={handleNotificationClose}
                >
                  {notification.message}
                </MenuItem>
              ))
            ) : (
              <MenuItem onClick={handleNotificationClose}>
                Sem notificações
              </MenuItem>
            )}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
