import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../utils/api";
import { CheckCircle, Error } from "@mui/icons-material";

const ElectionInfo = ({ election }) => (
  <Box
    sx={{
      border: "1px solid",
      borderColor: election?.status === "Aberta" ? "green" : "red",
      borderRadius: "8px",
      padding: 2,
      margin: 2,
    }}
  >
    <Typography variant="h5" color="primary">
      {`Ano: ${election?.year}`}
    </Typography>
    <Typography
      variant="h6"
      color="primary"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {election?.status === "Aberta" ? (
        <>
          <CheckCircle sx={{ color: "green", marginRight: 1 }} />
          {`Status: ${election?.status} - Aberta para inscrição de chapas`}
        </>
      ) : (
        <>
          <Error sx={{ color: "red", marginRight: 1 }} />
          {`Status: ${election?.status}`}
        </>
      )}
    </Typography>
  </Box>
);

const OpenElectionsButton = ({ onClick }) => (
  <Button variant="contained" onClick={onClick}>
    Abrir Período de Eleições
  </Button>
);

export const OpenEleicoes = () => {
  const [election, setElection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchCurrentYearElection = async () => {
    const currentYear = new Date().getFullYear().toString();
    try {
      const response = await api.get(`/admin/election/year/${currentYear}`);
      setElection(response.data.election);
    } catch (err) {
      console.error("Erro ao buscar eleição do ano atual:", err);
      setError("Falha ao buscar eleições");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentYearElection(); // Chama a função ao montar o componente
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmOpenElections = async () => {
    try {
      const response = await api.post("/admin/election", {});
      setElection(response.data.newElection);
      handleCloseDialog();
      // Atualiza a eleição após abrir
      await fetchCurrentYearElection(); // Chame novamente para buscar as eleições atualizadas
    } catch (error) {
      console.error("Erro ao abrir eleições:", error);
      setError("Falha ao abrir o período de eleições");
      handleCloseDialog();
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="body1">
        {error}
      </Typography>
    );
  }

  return (
    <Box textAlign="center">
      {!election ? (
        <>
          <OpenElectionsButton onClick={handleOpenDialog} />
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Confirmar Ação</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você realmente deseja abrir o período de eleições?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleConfirmOpenElections} color="primary">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <ElectionInfo election={election} />
      )}
    </Box>
  );
};
