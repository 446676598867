import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardActions,
  Grid,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";

export const PaginaVotacao = () => {
  const [chapas, setChapas] = useState([]);
  const [flags, setFlags] = useState([]);
  const [voto, setVoto] = useState([]);
  const { userId } = useAuth();
  // const userId = 11;
  const [isVotingAllowed, setIsVotingAllowed] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedChapaId, setSelectedChapaId] = useState(null);
  const [selectedVoto, setSelectedVoto] = useState(null);

  // Função para buscar flags
  const fetchChapas = async () => {
    try {
      const response = await api.get("/auth/chapas");
      if (response?.data?.chapas) {
        setChapas([response.data.chapas[0]]);
      }
    } catch (error) {
      console.error("Erro ao buscar chapas:", error);
    }
  };
  const fetchFlags = async () => {
    try {
      await api
        .get(`/auth/flags/${userId}`)
        .then((response) => {
          if (response?.data?.flags) {
            setFlags(response.data.flags);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error("Erro ao buscar chapas:", error);
    }
  };
  const fetchisVoteFlags = async () => {
    try {
      const response = await api.get(`/auth/voto/user/${userId}`);
      if (response?.data?.voto) {
        setVoto(response.data.voto);
      }
    } catch (error) {
      setVoto({ votou2024: false });
      console.error("Erro ao buscar chapas:", error);
    }
  };

  useEffect(() => {
    fetchChapas();
    fetchFlags();
    fetchisVoteFlags();
    checkVotingTime();
  }, []);

  // Função para verificar se está dentro do horário de votação
 const checkVotingTime = () => {
   const now = new Date();
   const currentHour = now.getHours();
   const currentMinutes = now.getMinutes();

   // Permitir votação entre 12:00 e 23:30
   const isAllowed =
     (currentHour > 12 && currentHour < 23) || // Entre 13h e 22h59
     (currentHour === 23 && currentMinutes <= 30) || // Exatamente até 23:30
     (currentHour === 12 && currentMinutes >= 0); // Exatamente 12:00

   setIsVotingAllowed(isAllowed);
 };

  // Função para abrir o diálogo de confirmação
  const handleOpenConfirmationDialog = (chapaId, votoTipo) => {
    setSelectedChapaId(chapaId);
    setSelectedVoto(votoTipo);
    setOpenConfirmationDialog(true);
  };

  // Função para registrar o voto após a confirmação
  const handleVotoConfirmado = async () => {
    if (selectedChapaId && selectedVoto !== null) {
      const votoData = {
        chapaId: selectedChapaId,
        usuarioId: userId,
        voto: {
          usuarioId: userId,
          chapaId: selectedChapaId,

          quantidade: 1,
          aprovacao: selectedVoto === "aprovado" ? 1 : 0,
          reprovacao: selectedVoto === "reprovado" ? 1 : 0,
        },
        flags: {
          ...flags,
          votou2024: true,
          voto2024: selectedVoto === "aprovado" ? "aprovado" : "reprovado",
        },
      };
      setSelectedVoto({ votou2024: true });

      try {
        const response = await api.post("/auth/voto", votoData);
        if (response.status === 201) {
          // Atualize a contagem de votos localmente
          setChapas((prevChapas) =>
            prevChapas.map((chapa) =>
              chapa.id === selectedChapaId
                ? {
                    ...chapa,
                    votos: (chapa.votos || 0) + 1,
                    aprovacao:
                      selectedVoto === "aprovado"
                        ? chapa.aprovacao + 1
                        : chapa.aprovacao,
                    reprovacao:
                      selectedVoto === "reprovado"
                        ? chapa.reprovacao + 1
                        : chapa.reprovacao,
                  }
                : chapa
            )
          );
        }
      } catch (error) {
        console.error("Erro ao registrar voto:", error);
      }
    }
    setOpenConfirmationDialog(false); // Fecha o diálogo após o voto ser registrado
  };

  // Função para fechar o diálogo sem registrar voto
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" textAlign="center" sx={{ marginBottom: 3 }}>
        Votação de Chapas
      </Typography>

      {!isVotingAllowed && (
        <Alert severity="info" sx={{ marginBottom: 3 }}>
          A votação está disponível apenas entre 12h e 23h.
        </Alert>
      )}

      {!voto?.votou2024 ? (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {chapas.map((chapa) => (
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              key={chapa.id}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card elevation={3} sx={{ width: "100%" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    {chapa.nome}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Membros:
                  </Typography>
                  <List dense>
                    {chapa.membros.map((membro, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={membro.nome}
                          secondary={`Cargo: ${membro.cargo}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      handleOpenConfirmationDialog(chapa.id, "aprovado")
                    }
                    disabled={!isVotingAllowed || flags?.votou2024} // Impede votar se já votou
                  >
                    Aprovar
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleOpenConfirmationDialog(chapa.id, "reprovado")
                    }
                    sx={{ marginLeft: 2 }}
                    disabled={!isVotingAllowed || flags?.votou2024} // Impede votar se já votou
                  >
                    Reprovar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" textAlign="center" sx={{ marginBottom: 3 }}>
            Você já votou nessa eleição
          </Typography>
        </Box>
      )}

      {/* Diálogo de confirmação de voto */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>Confirmar Voto</DialogTitle>
        <DialogContent>
          <Typography>
            Você tem certeza que deseja{" "}
            {selectedVoto === "aprovado" ? "aprovar" : "reprovar"} esta chapa?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleVotoConfirmado} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
