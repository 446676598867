import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import api from "../../../utils/api";

export const PaginaVotacao = () => {
  const [chapas, setChapas] = useState([
    { id: 1, nome: "Chapa A", votos: 10 },
    { id: 2, nome: "Chapa B", votos: 5 },
  ]);
  const fetchChapas = async () => {
    api.get("/admin/chapas").then((response) => {
      if (response?.data?.chapas) {
        setChapas(response?.data?.chapas);
      }
      console.log("response chapas ->");
      console.log(response);

      console.log("------------------");
    });
  };
  useEffect(() => {
    fetchChapas();
  }, []);
  const handleVotar = (id) => {
    setChapas(
      chapas.map((chapa) => {
        if (!chapas.votos || chapas.votos === undefined) {
          chapas.votos = 0;
        }
        return chapa.id === id ? { ...chapa, votos: chapa.votos + 1 } : chapa;
      })
    );
  };

  return (
    <Box>
      <Typography variant="h5">Votação</Typography>
      <List>
        {chapas.map((chapa) => (
          <ListItem key={chapa.id}>
            <ListItemText primary={`${chapa.nome} - Votos: ${chapa.votos}`} />
            <Button variant="contained" onClick={() => handleVotar(chapa.id)}>
              Votar
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
