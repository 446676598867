import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export const EventsViewTable = ({ data }) => {
  return (
    <div>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="w-full" aria-label="events table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Localização</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => {
              return (
                <TableRow key={item.event.id}>
                  <TableCell>{item.event.id}</TableCell>
                  <TableCell>{item.event.title}</TableCell>
                  <TableCell>{item.event.description}</TableCell>
                  <TableCell>{item.event.date}</TableCell>
                  <TableCell>{item.event.location}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
