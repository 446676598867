import React, { useState } from "react";
import { Typography, Button, ButtonGroup, Paper } from "@mui/material";

const devices = [
  "Desktop (Large View)",
  "Tablet (Medium View)",
  "Mobile (Small View)",
  "Small Mobile (Extra Small View)",
  "Extra Small Mobile (Extra Extra Small View)",
];

export const DashboardEditor = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dashboardData, setDashboardData] = useState({
    large: [],
    medium: [],
    small: [],
    extraSmall: [],
    extraExtraSmall: [],
  });

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleAddCard = () => {
    const key = viewportKeys[currentStep];
    setDashboardData((prevData) => ({
      ...prevData,
      [key]: [...prevData[key], `Card ${prevData[key].length + 1}`],
    }));
  };

  const viewportKeys = Object.keys(dashboardData);

  const renderStepContent = () => {
    return (
      <StepForm
        title={devices[currentStep]}
        onNextStep={handleNextStep}
        onBackStep={handleBackStep}
        onSave={handleAddCard}
        existingCards={dashboardData[viewportKeys[currentStep]]}
      />
    );
  };

  return (
    <div className="p-4">
      <Typography variant="h4" gutterBottom>
        Dashboard Editor
      </Typography>
      {renderStepContent()}
      <ButtonGroup className="mt-4">
        <Button disabled={currentStep === 0} onClick={handleBackStep}>
          Back
        </Button>
        <Button
          disabled={currentStep === viewportKeys.length - 1}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </ButtonGroup>
      <div className="mt-4">
        <Typography variant="h5">All Viewports</Typography>
        <div className="grid grid-cols-5 gap-4">
          {dashboardData.large.map((card, index) => (
            <Paper key={index} className="p-4">
              {card}
            </Paper>
          ))}
        </div>
      </div>
    </div>
  );
};

const StepForm = ({ title, onNextStep, onBackStep, onSave, existingCards }) => {
  const handleSubmit = () => {
    onSave();
    onNextStep();
  };

  return (
    <div>
      <Typography variant="h5">{title}</Typography>
      <Button variant="contained" onClick={handleSubmit} className="mt-4">
        Add Card
      </Button>
      <Typography variant="h6" className="mt-4">
        Existing Cards
      </Typography>
      <div className="grid grid-cols-2 gap-4">
        {existingCards.map((card, index) => (
          <Paper key={index} className="p-4">
            {card}
          </Paper>
        ))}
      </div>
    </div>
  );
};
