// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FlashMessage.css */
.flash-message {
    position: fixed;
    top: 1.25rem;
    right: 1.25rem;
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 1rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.success {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.error {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/contexts/FlashMessageProvider/FlashMessage.css"],"names":[],"mappings":"AAAA,qBAAqB;AAEnB;IAAA,eAAgE;IAAhE,YAAgE;IAAhE,cAAgE;IAAhE,eAAgE;IAAhE,sBAAgE;IAAhE,aAAgE;IAAhE,oBAAgE;IAAhE;AAAgE;;AAIhE;IAAA,kBAAmB;IAAnB;AAAmB;;AAInB;IAAA,kBAAiB;IAAjB;AAAiB","sourcesContent":["/* FlashMessage.css */\r\n.flash-message {\r\n  @apply fixed top-5 right-5 p-4 rounded text-white cursor-pointer;\r\n}\r\n\r\n.success {\r\n  @apply bg-green-500;\r\n}\r\n\r\n.error {\r\n  @apply bg-red-500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
