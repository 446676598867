import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";
import { StyledButton } from "../../Buttons/StyledButton";

export const ProfileTable = ({ profiles, updateList = () => {} }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();
  const aproveRegister = async (id) => {
    // console.log(id);
    try {
      await api.patch(`/admin/profile`, { id: id });
      showFlashMessage("Cadastro Aprovado!", "success");
      // window.location.reload();
      updateList();
    } catch (error) {
      console.log("error");
    }
  };
  const deactiveUser = async (id) => {
    // console.log(id);
    try {
      await api.put(`/admin/flags/inative`, { userId: id });
      showFlashMessage("Usuario Desativado!", "success");
      // window.location.reload();
      updateList();
    } catch (error) {
      console.log("error");
      showFlashMessage("Usuario Desativado!", "error");
    }
  };
  const handleVisualize = (data) => {
    openModal("profile-modal", "consult", data);
  };
  const handleSeeDocuments = (data) => {
    openModal("image-modal", "", data);
  };
  const handleAproveRegister = (data) => {
    aproveRegister(data?.id);
  };
  const handleDeactiveUser = (data) => {
    deactiveUser(data?.id);
  };
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="flex items-center justify-center">
        <StyledButton onClick={() => handleVisualize(data)}>
          Visualizar Cadastro
        </StyledButton>
        <StyledButton onClick={() => handleSeeDocuments(data)}>
          Visualizar documentos
        </StyledButton>
        {/* {JSON.stringify(data?.user?.Flags[0]?.inativeUser)} */}

        {!data?.user?.Flags[0]?.inativeUser ? (
          data?.isApproved ? (
            <StyledButton onClick={() => handleAproveRegister(data)}>
              Desfazer aprovação
            </StyledButton>
          ) : (
            <StyledButton onClick={() => handleAproveRegister(data)}>
              Aprovar cadastro
            </StyledButton>
          )
        ) : (
          <div></div>
        )}
        {data?.user?.Flags[0]?.inativeUser ? (
          <StyledButton onClick={() => handleDeactiveUser(data?.user)}>
            Re Ativar
          </StyledButton>
        ) : (
          <StyledButton onClick={() => handleDeactiveUser(data?.user)}>
            Desativar
          </StyledButton>
        )}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Linha",
        selector: (row) => row?.busLine?.name,
        sortable: true,
      },
      {
        name: "Insituição",
        selector: (row) => row?.institute?.name,
        sortable: true,
      },
      {
        name: "Curso",
        selector: (row) => row?.CourseOnInstitute?.course?.name,
        sortable: true,
      },

      {
        name: "Situação",
        cell: (row) => {
          console.log("sit row", row?.user?.Flags?.inativeUser);
          console.log("sit row", row?.user, row?.user?.Flags[0]);
          if (row?.user?.Flags[0]?.inativeUser) {
            return "Inativo";
          }
          return row?.isApproved ? "Aprovado" : "Aguardando aprovação";
        },
        sortable: true,
        sortFunction: (a, b, direction) => {
          const statusA = a?.isApproved ? "Aprovado" : "Aguardando aprovação";
          const statusB = b?.isApproved ? "Aprovado" : "Aguardando aprovação";

          if (direction === "asc") {
            return statusA.localeCompare(statusB);
          } else {
            return statusB.localeCompare(statusA);
          }
        },
      },
      {
        name: "Ações",
        cell: (row) => (
          <button onClick={() => handleDelete(row.id)}>Deletar</button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setData(profiles);
  }, [profiles]);
  const handleDelete = async (id) => {
    const confirmation = window.confirm(
      "Deseja realmente excluir este registro?"
    );

    if (!confirmation) {
      return;
    }

    console.log(`Excluir perfil com ID: ${id}`);
    try {
      api
        .delete("/admin/profile", { data: { id } })
        .then((response) => {
          // Lógica de tratamento para uma resposta bem-sucedida
          console.log("Exclusão bem-sucedida", response);
          // window.location.reload();
          updateList();
          showFlashMessage("Exclusão bem-sucedida!", "success");
        })
        .catch((error) => {
          // Lógica de tratamento para erros
          console.error("Erro ao excluir", error);
          showFlashMessage("Falha ao excluir!", "error");
        });
    } catch (error) {
      console.log(`Erro ao excluir perfil com ID: ${id}`);
    }
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        expandableRows
        expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        onRowClicked={handleRowClick}
        expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
};
