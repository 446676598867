import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Cadastro } from "../pages/Cadastro";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { CarteirinhaPage } from "../pages/Carteirinha";

import useAuth from "../hooks/useAuth";
import { AdminLayout } from "../layouts/AdminLayout";
import { Events } from "../pages/manager/Events";
import { ProfilePage } from "../pages/manager/Profiles";
import { LineManagementPage } from "../pages/manager/LineManagementPage";
import { InstitutesPage } from "../pages/manager/InstitutePage";
import { CoursesPage } from "../pages/manager/CoursesPage";
import { TermsPage } from "../pages/Terms";
import useFlags from "../hooks/userFlags";
import { PaymentPage } from "../pages/manager/PaymentsPage";
import { Payments } from "../pages/Payments";
import { DashboardEditor } from "../pages/manager/Dashboard/DashboardEditor";
import { ReportsCentralPage } from "../pages/manager/ReportsCentralPage";

import { CoursexlinePage } from "../pages/manager/ReportsCentralPage/reports/Coursexline";
import { APIRequest, } from "../pages/manager/ApiPage/ApiPage";
import { UsersByLine } from "../pages/manager/ReportsCentralPage/reports/UsersByLine";
import { AdminOuvidoria } from "../pages/manager/Ouvidoria";
import { Ouvidoria } from "../pages/Ouvidoria";
import { Chats } from "../pages/Ouvidoria/chats";
import { InstituteByLine } from "../pages/manager/ReportsCentralPage/reports/InstituteByLine";
import { PassengerReport } from "../pages/manager/ReportsCentralPage/reports/PassengerReport";
import { AdminDeclaracoes } from "../pages/manager/Declaracoes";
import { DeclaracoesErrorsPage } from "../pages/manager/Declaracoes/ErrosPage/ErrorsPage";
import { ChangeApiPage } from "../pages/manager/ChangeApiPage/ApiPage";
import { EleicoesPage } from "../pages/manager/EleicoesPage/EleicoesPage";
import { CrudChapa } from "../pages/manager/EleicoesPage/CrudChapa";
import { Votacao } from "../pages/Votacao";

// Configuração das rotas administrativas
export const userRoutes = [
  { path: "/home", element: <Cadastro /> },
  { path: "/financeiro", element: <Payments /> },
  // { path: "/votacao", element: <Votacao /> },
  { path: "/cadastro", element: <Cadastro /> },
  { path: "/ouvidoria", element: <Ouvidoria /> },
  { path: "/ouvidoria/chats", element: <Chats /> },
  { path: "/carteirinha", element: <CarteirinhaPage /> },
];

export const adminRoutes = [
  { path: "/", element: <Home /> },
  { path: "/manager/ouvidoria", element: <AdminOuvidoria /> },
  { path: "/manager/declaracoes", element: <AdminDeclaracoes /> },
  { path: "/manager/declaracoes/errors", element: <DeclaracoesErrorsPage /> },
  { path: "/manager", element: <Home /> },
  { path: "/manager/reports", element: <ReportsCentralPage /> },
  { path: "/manager/reports/userbyline", element: <UsersByLine /> },
  { path: "/manager/reports/institutebyline", element: <InstituteByLine /> },
  { path: "/manager/reports/coursexline", element: <CoursexlinePage /> },
  { path: "/manager/reports/passenger-report", element: <PassengerReport /> },
  // { path: "/manager/reports", element: <Tabela /> },
  { path: "/manager/login", element: <Login /> },
  { path: "/manager/payments", element: <PaymentPage /> },
  { path: "/manager/events", element: <Events /> },
  { path: "/manager/profiles", element: <ProfilePage /> },
  { path: "/manager/lines", element: <LineManagementPage /> },
  { path: "/manager/faculdades", element: <InstitutesPage /> },
  { path: "/manager/cursos", element: <CoursesPage /> },
  { path: "/manager/dashboard", element: <DashboardEditor /> },
  { path: "/manager/api", element: <APIRequest /> },
  { path: "/manager/dev", element: <ChangeApiPage /> },
  // { path: "/manager/eleicoes", element: <EleicoesPage /> },
  { path: "/manager/eleicoes", element: <CrudChapa /> },
];

export function AppRoutes() {
  const { authenticated, isAdmin } = useAuth();

  const { flags, loading, updateFlags } = useFlags();
  // console.log(
  //   "flags",
  //   loading,
  //   flags?.acceptedTerms,
  //   !flags?.acceptedTerms,
  //   flags
  // );

  if (authenticated) {
    const routes = isAdmin ? [...userRoutes, ...adminRoutes] : userRoutes;
    const layout = isAdmin ? <AdminLayout /> : <DefaultLayout />;

    if (!loading) {
      if (!flags?.acceptedTerms) {
        setTimeout(() => {
          updateFlags();
        }, 3000);

        return (
          <Routes>
            <Route path="/" element={layout}>
              <Route path="/" element={<TermsPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="*" element={<Navigate to="/terms" />} />
            </Route>
          </Routes>
        );
      }

      return (
        <Routes>
          <Route path="/" element={layout}>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      );
    }
  } else {
    return (
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Cadastro />} />
        </Route>
      </Routes>
    );
  }
}
