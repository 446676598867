import React from "react";
import { DefaultContainer } from "../../../components/DefaultContainer";
import { OpenEleicoes } from "./OpenEleicoes";

export const EleicoesPage = () => {
  return (
    <DefaultContainer>
      <OpenEleicoes />
    </DefaultContainer>
  );
};
